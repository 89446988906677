import Image from "next/image";
import Link from "next/link";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "./MiddleBar.module.scss";
import SearchBox from "./search-box/SearchBox";
import { useSelector } from "react-redux";
import searchicon from "../../../assets/images/squiggly-arrow.png";
import carda from "../../../assets/images/p1.png";
import logo from "../../../assets/images/logo-new.png";
import { useAppDispatch } from "@/store";
import { authActions, selectAuth } from "@/store/slices/auth";
import { Spinner } from "react-bootstrap";
import { fbqTrackEvent } from "@/lib/fpixel";
import { useRouter } from "next/router";
import Marquee from "react-easy-marquee";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { selectCart } from "@/store/slices/cart";
import { selectWishlist } from "@/store/slices/wishlist";
import httpClient from "@/api/http-client";
import endpoints from "@/api/endpoints";
import CurrencyInr from "@/components/common/currency-inr";
import { useDebounce } from "usehooks-ts";
SwiperCore.use([Navigation, Pagination]);

const MiddleBar = ({}: any) => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuth);
  const cartState = useSelector(selectCart);
  const wishlistState = useSelector(selectWishlist);
  // const [isHovered, setIsHovered] = useState(false);
  const router = useRouter();
  const [search, setSearch] = useState("");
  const [loadbox, setLoadbox] = useState(false);
  const [imgbox, setImgbox] = useState(false);

  const [searchData, setSearchData] = useState<any>(null);
  const debouncedValue = useDebounce<string>(search, 500);
  const newRef = useRef(null);
  const handleOutsideClick = (e: any) => {
    //@ts-ignore
    if (newRef.current && !newRef.current.contains(e.target)) {
      setLoadbox(false);
    }
  };

  const fetchData = async (value: any) => {
    if (value?.length && loadbox) {
      try {
        const { data } = await httpClient.post(
          endpoints.products.autoComplete(),
          {
            q: value,
            productsCount: 5,
            sort: JSON.stringify([
              { field: "relevance", order: "asc" },
              { field: "sellingPrice", order: "asc" },
            ]),
          },
          {
            headers: {
              "x-store-id": `${process.env.NEXT_PUBLIC_WIZZY_STOREID}`,
              "x-api-key": `${process.env.NEXT_PUBLIC_WIZZY_APIKEY}`,
            },
          }
        );
        setSearchData(data.payload);
      } catch (error: any) {
        setSearchData(null);
      }
    } else {
      setSearchData(null);
    }
  };
  useEffect(() => {
    fetchData(debouncedValue);
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    if (router.query.q) {
      setSearch(router.query.q as string);
    } else {
      setSearch("");
    }
    setLoadbox(false);
  }, [router.query]);
  // useEffect(() => {
  //   setIsHovered(true);
  // }, []);

  const searchSubmit = (e: any) => {
    e.preventDefault();
    if (search.trim()) {
      fbqTrackEvent("Search", { search_string: search });
      router.replace(
        {
          pathname: "/jewellery",
          query: {
            q: search,
          },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  return (
    <Fragment>
      <div className="container d-none">
        <div className="row">
          <div className={styles.header_logo_section}>
            <div className={styles.w_60}>
              <div className={styles.header_icon_box}>
                <div className={styles.header_icon}>
                  <Link href="/" passHref>
                    <Image
                      className="img-fluid pointer"
                      src={require("@/assets/images/logo-img.png")}
                      alt="Logo"
                      priority
                      unoptimized
                      width={167}
                      height={56}
                    />
                  </Link>
                </div>
              </div>

              <div className={styles.logo}>
                <Link href="/" passHref>
                  <Image
                    className="img-fluid pointer"
                    src={require("@/assets/images/logo.png")}
                    alt="Logo"
                    priority
                    unoptimized
                  />
                </Link>
              </div>
            </div>

            <SearchBox />
          </div>
        </div>
      </div>

      {true && (
        <div className={`${styles.main_header} ${"pb-0"}`}>
          {false && (
            <Marquee duration={15000} height="20px" reverse>
              <p className="mb-0 top-text-topbar" style={{ color: "red" }}>
                We are upgrading our payment system! Checkout is temporarily
                unavailable, but you can still browse our products. Thank you
                for your patience!
                {/* Enjoy Rs. 500 off on your first purchase. Sign Up Now! *T&C
                applicable */}
                {/* <strong>
              We are upgrading our site for a better experience, you might face
              some technical glitches. We apologize for any inconvenience this
              may cause. Thank you for your patience.
            </strong> */}
                {/* Our website is currently undergoing maintenance. Some links might
            not work for the next 2-3 hours. If you experience any difficulties,
            please contact customer support. Thank you for your understanding. */}
              </p>
            </Marquee>
          )}
        </div>
      )}
      {/* add fixed */}

      <div className={`${styles.main_header}`}>
        <div className="container">
          <div className={styles.header_container}>
            <div className={styles.logo}>
              <Link href="/" passHref>
                <div className="d-flex">
                  <Image src={logo} alt="Brand Logo" priority />
                </div>
              </Link>
            </div>
            <div className={styles.search} ref={newRef}>
              <form className={styles.search_form} onSubmit={searchSubmit}>
                <input
                  type="text"
                  placeholder="SEARCH"
                  value={search}
                  onChange={async (e) => {
                    setSearch(e.target.value);
                    setLoadbox(true);
                  }}
                />
                <button
                  role="button"
                  aria-label="Camera"
                  className={`${styles.search_button} --syte-start-camera-upload`}
                  type="button"
                  style={{ right: 35 }}
                  data-camera-button-placement="search-bar"
                >
                  <div
                    className={styles.icon}
                    // onMouseEnter={() => setIsHovered(true)}
                    // onMouseLeave={() => setIsHovered(false)}
                  >
                    <i
                      className="bi bi-camera"
                      style={{ fontSize: 18, color: "#656565" }}
                    ></i>

                    {/* {isHovered && (
                      <div className={styles.drop_hover}>
                        <i className="bi bi-caret-up-fill"></i>
                        <div className={styles.drop_hover_head}>
                          <div className={styles.drop_card}>
                            <a>
                              <i
                                className="bi bi-x"
                                onClick={() => setIsHovered(false)}
                              ></i>
                            </a>
                            <p className="pt-3 m-0">NEW!</p>
                            <p className={styles.visual}>Visual Search</p>
                            <p className={styles.take}>
                              Take a photo or Upload image to find similar items
                            </p>
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </button>
                <button
                  role="button"
                  aria-label="Search"
                  className={styles.search_button}
                  type="submit"
                >
                  <div
                    className={styles.icon}
                    onClick={() => {
                      setSearchData(null);
                      setLoadbox(false);
                      setSearch("");
                    }}
                  >
                    {!search.length ? (
                      <Image
                        className="img-fluid"
                        src={require("@/assets/images/searchicon.png")}
                        alt="Search icon"
                        priority
                        unoptimized
                      />
                    ) : (
                      <Image
                        className={`${styles.crosss} ${"img-fluid"}`}
                        src={require("@/assets/images/cross-sign.png")}
                        alt="Cross icon"
                        priority
                        unoptimized
                      />
                    )}
                  </div>
                </button>
              </form>
              {searchData && loadbox && (
                <div className={styles.suggestions}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={styles.suggestions_section_header}>
                        Categories
                      </div>
                      <ul>
                        {searchData &&
                          searchData?.categories?.map((v: any) => (
                            <>
                              <li
                                onClick={() => {
                                  router.push({
                                    pathname: `/jewellery/category/${
                                      v.payload
                                        ?.find(
                                          (x: any) =>
                                            x.key === "relatedCategories"
                                        )
                                        ?.value.reverse()[0]
                                        .url?.split("/jewellery/category/")[1]
                                    }`,
                                  });
                                  setSearchData(null);
                                  return;
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <a>
                                  {" "}
                                  <span>
                                    {v.payload
                                      ?.find(
                                        (x: any) =>
                                          x.key === "relatedCategories"
                                      )
                                      ?.value?.map((x: any) => x.name)
                                      .join(" > ") || v.value}
                                  </span>{" "}
                                  <span className="span">
                                    <Image src={searchicon} alt="Search Icon" />{" "}
                                  </span>
                                </a>
                              </li>
                            </>
                          ))}
                      </ul>
                      {searchData?.products && (
                        <>
                          <div className={styles.suggestions_section_header}>
                            Popular Products
                          </div>
                          <div className={styles.popular_products_search}>
                            <div className={styles.popular_products}>
                              <Swiper
                                className="swiper-navigation-black"
                                spaceBetween={10}
                                slidesPerView={3}
                                navigation={{
                                  nextEl: ".arrow_left",
                                  prevEl: ".arrow_right",
                                }}
                              >
                                {searchData?.products?.result.map((v: any) => (
                                  <>
                                    <SwiperSlide>
                                      <Link href={v.url} passHref>
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className={styles.swiper_card_border}
                                        >
                                          <div
                                            className={
                                              styles.popular_products_card
                                            }
                                          >
                                            <Image
                                              src={v?.mainImage}
                                              alt={v.name}
                                              height={103}
                                              width={102}
                                            />
                                            <p>{v.name} </p>
                                            <p>
                                              {" "}
                                              <CurrencyInr
                                                value={v.sellingPrice}
                                              />
                                            </p>
                                          </div>
                                        </a>
                                      </Link>
                                    </SwiperSlide>
                                  </>
                                ))}
                              </Swiper>
                            </div>
                            <button
                              aria-label="Arrow Left"
                              role="button"
                              className={`${styles.arrow_left} ${"arrow_left"}`}
                            >
                              <i className="bi bi-chevron-left"></i>
                            </button>
                            <button
                              role="button"
                              aria-label="Arrow right"
                              className={`${
                                styles.arrow_right
                              } ${"arrow_right"}`}
                            >
                              <i className="bi bi-chevron-right"></i>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-md-8"></div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.users_container}>
              <ul>
                {authState.user ? (
                  <li>
                    <Link href={"/account/profile"}>
                      <a>
                        <Image
                          className="img-fluid pointer"
                          src={require("@/assets/images/account.svg")}
                          width={29}
                          height={29}
                          alt="Go to Account"
                          priority
                          unoptimized
                        />
                        <span>Account</span>
                      </a>
                    </Link>
                  </li>
                ) : (
                  <li>
                    {authState.isUserLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <span
                        onClick={() => {
                          dispatch(authActions.setIsModalOpen(true));
                        }}
                      >
                        <Image
                          className="img-fluid pointer"
                          src={require("@/assets/images/account.svg")}
                          width={29}
                          height={29}
                          alt="Login to account"
                          priority
                          unoptimized
                        />
                        <span>Account</span>
                      </span>
                    )}
                  </li>
                )}

                <li>
                  <Link href="/wishlist">
                    <a>
                      <Image
                        className="img-fluid pointer"
                        src={require("@/assets/images/wishlist.svg")}
                        alt="Go to Wishlist"
                        width={29}
                        height={29}
                        priority
                        unoptimized
                      />
                      <span>Wishlist</span>
                      {!!wishlistState.items.length && (
                        <div className={styles.top_card}>
                          {wishlistState.items.length}
                        </div>
                      )}
                    </a>
                  </Link>
                </li>

                <li>
                  <Link href="/cart">
                    <a>
                      <Image
                        className="img-fluid pointer"
                        src={require("@/assets/images/cart.svg")}
                        alt="Go to Cart"
                        priority
                        unoptimized
                        width={29}
                        height={29}
                      />
                      <span>Cart</span>
                      {!!cartState.items.length && (
                        <div className={styles.top_card_card}>
                          {cartState.items.length}
                        </div>
                      )}
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/store-locator">
                    <a>
                      <Image
                        className="img-fluid pointer"
                        src={require("@/assets/images/store-l.svg")}
                        alt="store"
                        priority
                        unoptimized
                        width={29}
                        height={29}
                      />
                      <span>Stores</span>
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.custom_flex}>
              <ul>
                <li>
                  <a
                    href="https://wa.me/7605023222"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      className="img-fluid pointer"
                      src={require("@/assets/images/whatsapp.svg")}
                      alt="Whatsapp"
                      priority
                      unoptimized
                      width={21}
                      height={21}
                    />
                    <span className="d-inline"> 7605023222</span>
                  </a>
                </li>
                <li>
                  <a href="tel://18001030017">
                    <Image
                      className="img-fluid pointer"
                      src={require("@/assets/images/customer-service-agent.svg")}
                      alt="Customer Care"
                      width={21}
                      height={21}
                      priority
                      unoptimized
                    />
                    <span className="d-inline">18001030017</span>
                  </a>
                </li>
                <li>
                  <Dropdown
                    className={styles.drop_down_main}
                    // drop={"down-centered"}
                  >
                    <Dropdown.Toggle
                      className={styles.drop_price_change}
                      id="dropdown-basic"
                    >
                      <Image
                        className="img-fluid pointer"
                        src={require("@/assets/images/ind.svg")}
                        alt="Customer Care"
                        width={30}
                        height={30}
                        priority
                        unoptimized
                      />{" "}
                      &nbsp;
                      <span>INR</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Image
                          className="img-fluid pointer"
                          src={require("@/assets/images/ind.svg")}
                          alt="Customer Care"
                          width={20}
                          height={20}
                          priority
                          unoptimized
                        />{" "}
                        &nbsp; INR
                      </Dropdown.Item>
                      <Dropdown.Item href="https://sencogoldanddiamonds.us/">
                        <Image
                          className="img-fluid pointer"
                          src={require("@/assets/images/us.svg")}
                          alt="Customer Care"
                          width={20}
                          height={20}
                          priority
                          unoptimized
                        />{" "}
                        &nbsp; USD
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MiddleBar;
